const AppConfigDefs = {
    app: {
        abreviatura: "SIN",
        nome: "Sistema de Inteligência de Negócio",
        empresa: "Snap",
        footer: "Snap Inteligência de Dados - 2024"
    },
    login: {
        logo: {
            ligth: 'layout/snap/images/logo-light.png',
            dark: 'layout/snap/images/logo-dark.png',
            style: 'height: 84px;'
        },
        logoname: {
            ligth: 'layout/snap/images/logo-nome-light.png',
            dark: 'layout/snap/images/logo-nome-dark.png',
            style: 'width: 210px;'
        }
    },
    topbar: {
        logo: {
            ligth: 'layout/snap/images/logo-light.png',
            dark: 'layout/snap/images/logo-dark.png',
            style: 'height:34px; padding-left: 7px;'
        },
        logoname: {
            ligth: 'layout/snap/images/logo-nome-light.png',
            dark: 'layout/snap/images/logo-nome-dark.png',
            style: 'padding-left: 5px; padding-top: 0px; height:40px;'
        }
    },
    theme: {
        colorScheme: 'light',
        menu: 'purple',
        component: 'purple',
        menuMode: 'horizontal'
    },
    sifm: true
};

export default AppConfigDefs;